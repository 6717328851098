import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appScrollZindex]'
})
export class ScrollZindexDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    // Get the current scroll position
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // Check if the scroll position is at the top (0, 0)
    console.log(scrollPosition)
    if (scrollPosition === 0) {
      // Set z-index to a high value
      this.el.nativeElement.style.setProperty('z-index', '1000', 'important');
    } else {
      // Reset z-index to its initial value or a lower value
      this.renderer.setStyle(this.el.nativeElement, 'z-index', 'auto');
    }
  }

}
