import { Component, Input, OnDestroy, OnInit } from '@angular/core';
@Component({ selector: 'app-response-message', templateUrl: './response-message.component.html', styleUrls: ['./response-message.component.scss'] })
export class ResponseMessageComponent implements OnInit, OnDestroy {
  @Input() public responseMessage!: string;
  @Input() public messageStatus!: string;
  public hideMessage: boolean = false;
  constructor() {}
  public ngOnInit(): void {}
  public ngOnDestroy(): void {}
  public hideResponseMessage(): void { this.hideMessage = true; }
}
