import { Injectable } from '@angular/core';
import { Blog, FAQ, FAQResponse } from '../models/media';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { environment as env } from '../environments/environment';
import { IBlog, IFAQ } from '../models/interfaces/media.model';
@Injectable({
  providedIn: 'root'
})
export class MediaService {
  apiURLBlog = `${env.apiUrl}media/blogs/`;
  apiURLFAQ = `${env.apiUrl}media/faqs/`;
  apiURLAdminBlog = `${env.apiUrl}media/admin-blogs/`;
  apiURLAdminFAQ = `${env.apiUrl}media/admin-faqs/`;

  constructor(private http: HttpClient) { }
  getAdminFAQs(param: {} = {}): Observable<IFAQ> {
    const params = new HttpParams({ fromObject: param });
    return this.http.get<IFAQ>(`${this.apiURLAdminFAQ.slice(0, -1)}?${params}`, { withCredentials: true });
  }
  getUserFAQs(): Observable<IFAQ> {
    return this.http.get<IFAQ>(`${this.apiURLFAQ.slice(0, -1)}`);
  }
  getAdminFAQ(faqId: string): Observable<FAQ> {
    return this.http.get<FAQ>(`${this.apiURLFAQ.substring(0, this.apiURLFAQ.length - 1) + "-"}admin/${faqId}`, { withCredentials: true });
  }
  getUserFAQ(faqSlug: string): Observable<FAQ> {
    return this.http.get<FAQ>(`${this.apiURLFAQ}${faqSlug}`);
  }
  createFAQ(Faq: FAQ): Observable<FAQResponse> {
    return this.http.post<FAQResponse>(`${this.apiURLFAQ}`, Faq, { withCredentials: true });
  }
  updateFAQ(Faq: FAQ): Observable<FAQResponse> {
    return this.http.patch<FAQResponse>(`${this.apiURLFAQ}${Faq._id}`, Faq, { withCredentials: true });
  }
  deleteFAQ(id: string): Observable<FAQ> {
    return this.http.delete<FAQ>(`${this.apiURLFAQ}${id}`, { withCredentials: true });
  }
  restoreFAQ(id: string): Observable<FAQ> {
    return this.http.post<FAQ>(`${this.apiURLFAQ}${id}/restore`, {}, { withCredentials: true });
  }
  /*=================================BLOGS==================================== */
  getAdminBlogs(param: {} | ''): Observable<IBlog> {
    // const params = new HttpParams({ fromObject: param })
    return this.http.get<IBlog>(`${this.apiURLAdminBlog.slice(0, -1)}${param}`, { withCredentials: true }).pipe(catchError(err => { throw new Error(err.message) }),);
  }
  getUserBlogs(): Observable<IBlog> {
    // const params = new HttpParams({ fromObject: param })
    return this.http.get<IBlog>(`${this.apiURLBlog.slice(0, -1)}`).pipe(catchError(err => { throw new Error(err.message) }));
  }
  getAdminBlog(blogId: string): Observable<Blog> {
    return this.http.get<Blog>(`${this.apiURLBlog.substring(0, this.apiURLBlog.length - 1) + "-"}admin/${blogId}`, { withCredentials: true });
  }
  getUserBlog(blogSlug: string): Observable<Blog> {
    return this.http.get<Blog>(`${this.apiURLBlog}${blogSlug}`);
  }
  createBlog(blog: Blog): Observable<Blog> {
    return this.http.post<Blog>(`${this.apiURLBlog}`, blog, { withCredentials: true });
  }
  updateBlog(blog: Blog): Observable<Blog> {
    return this.http.patch<Blog>(`${this.apiURLBlog}${blog._id}`, blog, { withCredentials: true });
  }
  deleteBlog(id: string): Observable<Blog> {
    return this.http.delete<Blog>(`${this.apiURLBlog}${id}`, { withCredentials: true });
  }
  restoreBlog(id: string): Observable<Blog> {
    return this.http.post<Blog>(`${this.apiURLBlog}${id}/restore`, {}, { withCredentials: true });
  }
}
