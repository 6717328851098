import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Count } from 'projects/shared/models/order';
import { OrderService } from 'projects/shared/services/order.service';
import { w1200 } from './sidebar.resizing';
import { noop, tap } from 'rxjs';
import { TicketSystemService } from 'projects/shared/services/ticket-system.service';
import { WalletService } from 'projects/shared/services/wallet.service';
import { StoreChangesService } from 'projects/shared/services/store-changes.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {

}
