<div class="d-flex justify-content-center">
  <p>{{ copy }}</p>
  <button class="btn btn-outline-success ml-3" type="button" name="copy-{{copy}}" id="copy-{{copy}}" cdkCopyToClipboard="{{copy}}"
    (cdkCopyToClipboardCopied)="copyToClipBoard()">
    <i class="pi pi-copy" title="Copy value"></i>
  </button>
  <p *ngIf="isCopied && !hide" class="text-center text-success m-1">
    copied
    <i class="pi pi-check text-success"></i>
  </p>
</div>
