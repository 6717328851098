import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss']
})
export class CopyComponent implements OnInit {
  @Input() public copy!: string;
  public isCopied = false;
  public hide!: boolean;

  constructor() {}

  public ngOnInit(): void {
    this.hide = false;
  }

  public copyToClipBoard() {
    this.isCopied = true;

    setTimeout(
      () => this.hide = true,
      5000,
    );
  }
}
