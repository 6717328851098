import axios from 'axios';
import { Injectable } from '@angular/core';
import { environment as env } from '../environments/environment';
@Injectable({ providedIn: 'root' })
export class UploadService {
  private url=`${env.apiUrl}upload`;
  constructor() {}
  public async upload(file?: File, folder?: string, config?: any) {
    try {
      const query = `?contentType=${file?.type}&filename=${file?.name}&folder=${folder}`;
      const uploadUrl = `${this.url}${!!file && !!folder ? query : ''}`;
      const data = await axios.get(uploadUrl, { withCredentials: true });
      await axios.put(data.data, file, config);
      return data.data.split("?")[0];
    } catch (error) {
      throw error;
    }
  }
}
