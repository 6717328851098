import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import * as fromMainCategoryReducer from 'projects/shared/reducers/category/main-category.reducer';
// import * as fromCategoryReducer from 'projects/shared/reducers/category/category.reducer';
// import * as fromSubCategoryReducer from 'projects/shared/reducers/category/sub-category.reducer';
// import { CategoryEffects } from 'projects/shared/effects/category.effect';
import * as fromUserReducer from 'projects/shared/reducers/user.reducer';
import * as fromProductReducer from 'projects/shared/reducers/product.reducer';
import * as fromLicenseReducer from 'projects/shared/reducers/license.reducer';
import * as fromNotificationReducer from 'projects/shared/reducers/notification.reducer';
import * as fromOrderReducer from 'projects/shared/reducers/order.reducer';
import * as fromCheckoutReducer from 'projects/shared/reducers/checkout.reducer';
import * as fromWalletReducer from 'projects/shared/reducers/wallet.reducer';
import * as fromBlogReducer from 'projects/shared/reducers/media/blogs.reducer';
import * as fromFAQReducer from 'projects/shared/reducers/media/faq.reducer';
import * as fromTicketSystemReducer from 'projects/shared/reducers/ticket-system.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from 'projects/shared/effects/user.effect';
import { ProductEffects } from 'projects/shared/effects/product.effect';
import { OrderEffects } from 'projects/shared/effects/order.effect';
import { CheckoutEffects } from 'projects/shared/effects/checkout.effect';
import { WalletEffects } from 'projects/shared/effects/wallet.effect';
import { NotificationEffects } from 'projects/shared/effects/notification.effect';
import { BlogEffects } from 'projects/shared/effects/media/blog.effect';
import { FAQEffects } from 'projects/shared/effects/media/FAQ.effect';
import { TeamWorkComponent } from './components/dashboard/team-work/team-work.component';
import { TicketSystemEffects } from 'projects/shared/effects/ticket-system.effect';
import { EmployeeComponent } from './components/dashboard/team-work/employee/employee.component';
import { ResellersComponent } from './components/dashboard/team-work/resellers/resellers.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { PrimengModule } from './shared/primeng.module';
import { SharedModule } from 'projects/shared/modules/shared.module';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { SideMenuComponent } from "./components/layout/side-menu/side-menu.component";
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    TeamWorkComponent,
    EmployeeComponent,
    ResellersComponent,
    HeaderComponent,
    SidebarComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    PrimengModule,
    SharedModule,
    EffectsModule.forRoot(),
    EffectsModule.forFeature([
        // CategoryEffects,
        UserEffects,
        ProductEffects,
        OrderEffects,
        WalletEffects,
        NotificationEffects,
        BlogEffects,
        FAQEffects,
        TicketSystemEffects,
        CheckoutEffects,
    ]),
    StoreModule.forRoot({}),
    // StoreModule.forFeature(
    //   fromMainCategoryReducer.mainCategoriesFeatureKey,
    //   fromMainCategoryReducer.reducer
    // ),
    // StoreModule.forFeature(
    //   fromCategoryReducer.categoriesFeatureKey,
    //   fromCategoryReducer.reducer
    // ),
    // StoreModule.forFeature(
    //   fromSubCategoryReducer.subCategoriesFeatureKey,
    //   fromSubCategoryReducer.reducer
    // ),
    StoreModule.forFeature(fromUserReducer.usersFeatureKey, fromUserReducer.reducer),
    StoreModule.forFeature(fromProductReducer.productsFeatureKey, fromProductReducer.reducer),
    StoreModule.forFeature(fromLicenseReducer.licensesFeatureKey, fromLicenseReducer.reducer),
    StoreModule.forFeature(fromNotificationReducer.notificationFeatureKey, fromNotificationReducer.reducer),
    StoreModule.forFeature(fromOrderReducer.orderFeatureKey, fromOrderReducer.reducer),
    StoreModule.forFeature(fromCheckoutReducer.checkoutFeatureKey, fromCheckoutReducer.reducer),
    StoreModule.forFeature(fromWalletReducer.walletFeatureKey, fromWalletReducer.reducer),
    StoreModule.forFeature(fromBlogReducer.blogFeatureKey, fromBlogReducer.reducer),
    StoreModule.forFeature(fromFAQReducer.FAQFeatureKey, fromFAQReducer.reducer),
    StoreModule.forFeature(fromTicketSystemReducer.ticketSystemFeatureKey, fromTicketSystemReducer.reducer),
    StoreModule.forFeature(fromOrderReducer.orderFeatureKey, fromOrderReducer.reducer),
    SideMenuComponent
],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
