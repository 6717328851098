<section [ngClass]="darkMode ? 'dark' : ''">
  <div class="side-menu">
    <div class="user">
      <img *ngIf="user?.avatar" [src]="user.avatar" class="rounded-circle">
      <p-skeleton shape="circle" width="50px" height="50px" *ngIf="!user?.avatar"></p-skeleton>
      <div class="name">
        <div class="role" *ngIf="user">{{ user.banStatus }}</div>
        <p-skeleton width="70px" height="18px" borderRadius="999px" *ngIf="!user?.banStatus"
          styleClass="mb-2"></p-skeleton>

        <div class="user-name text-capitalize" *ngIf="user"> {{ user.name ? user.name.split(' ')[0] : '' }}</div>
        <p-skeleton width="100px" height="18px" borderRadius="999px" *ngIf="!user?.name"></p-skeleton>

      </div>
      <div class="mode flex-grow-1 text-end"> <i [ngClass]="['pi', darkMode ? 'pi-sun text-light light-mode' : 'pi-moon dark-mode']"  (click)="[darkMode = !darkMode, setKeyForMode()] "></i>
      </div> 
    </div>
    <div class="items main">
      <div class="items-title">Main</div>
      <div class="item" *ngFor="let item of mainSideItems, let i = index;">
        <div class="link" [routerLink]="item.route ? [item.path, item.route] : item.path"
          (click)="[item.dropdown ? item.expanded = !item.expanded : '', collapseSiblings(i), item.dropdown ? onDropdownClick($event) : '']"
          [ngClass]="item.expanded || item.active ? 'route-active' : ''">
          <i [class]="item.icon"></i>
          <div class="label"><span> {{ item.label }}</span>
            <div class="icons d-flex align-items-center" style="gap: 10px;">
              <div class="mark" *ngIf="item.event?.have"></div>
              <i *ngIf="item.dropdown" [ngClass]="item.expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></i>
            </div>
          </div>
        </div>
        <div class="sub-menu" *ngIf="item.dropdown"
          [ngClass]="item.expanded ? 'expand-dropdown-'+item.subMenu?.length : ''">
          <div class="sub-menu-item" [ngClass]="subitem.active ? 'route-active' : ''"
            *ngFor="let subitem of item.subMenu"
            [routerLink]="subitem.route ? [subitem.path, subitem.route] : subitem.path">
            <div class="d-flex align-items-center justify-content-between">{{ subitem.label }} <div class="count"
                *ngIf="item.event?.have">{{ subitem.event?.value }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="items setting">
      <div class="items-title">Settings</div>
      <div class="d-flex flex-column justify-content-between links">
        @for (item of settingSideItems; track $index) {
        <div class="item" *ngIf="$index < 1">
          <div class="link" [routerLink]="item.route ? [item.path, item.route] : item.path"
            (click)="[item.dropdown ? item.expanded = !item.expanded : '', collapseSiblings($index)]"
            [ngClass]="item.expanded || item.active ? 'route-active' : ''">

            <i [class]="item.icon"></i>
            <div class="label"><span>{{ item.label }}</span><i *ngIf="item.dropdown"
                [ngClass]="item.expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></i></div>
          </div>
          <div class="sub-menu" *ngIf="item.dropdown"
            [ngClass]="item.expanded ? 'expand-dropdown-'+item.subMenu?.length : ''">
            <div class="sub-menu-item" [ngClass]="subitem.expanded || subitem.active ? 'route-active' : ''"
              [routerLink]="subitem.route ? [subitem.path, subitem.route] : subitem.path"
              *ngFor="let subitem of item.subMenu">{{ subitem.label }}</div>
          </div>
        </div>
        }
        <div class="side-footer">
          @for (item of settingSideItems; track $index) {
          <div *ngIf="$index > 0" class="item" [ngClass]="$index == settingSideItems.length - 1 ? 'logout' : ''">
            <div class="link" [routerLink]="item.route ? [item.path, item.route] : item.path"
              [ngClass]="[$index == settingSideItems.length - 1 ? 'mb-0' : '', item.expanded || item.active ? 'route-active' : '']"
              (click)="[item.dropdown ? item.expanded = !item.expanded : '', item.logout ? logout() : '']">
              <i [class]="item.icon"></i>
              <div class="label"><span>{{ item.label }}</span><i *ngIf="item.dropdown" class="pi pi-chevron-down"></i>
              </div>
            </div>
          </div>
          }
        </div>

      </div>
    </div>
  </div>
</section>