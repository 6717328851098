import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardGuard } from 'projects/shared/guards/dashboard.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TeamWorkComponent } from './components/dashboard/team-work/team-work.component';
import { EmployeeComponent } from './components/dashboard/team-work/employee/employee.component';
import { ResellersComponent } from './components/dashboard/team-work/resellers/resellers.component';
import { PartnersSliderComponent } from './components/panel/sliders-center/partners-slider/partners-slider.component';

const routes: Routes = [

  { path: '', redirectTo: 'dashboard',  pathMatch:'full'},
  { path: 'dashboard', component: DashboardComponent, canActivate: [DashboardGuard] },
  {
    path: 'sliders-center/partners',
    component: PartnersSliderComponent,
    canActivate: [DashboardGuard]
  },
  {
    path: 'dashboard/teamWork',
    component: TeamWorkComponent,
    canActivate: [DashboardGuard]
  },
  {
    path: 'dashboard/teamWork/employee',
    component: EmployeeComponent,
    canActivate: [DashboardGuard]
  },
  {
    path: 'dashboard/teamWork/resellers',
    component: ResellersComponent,
    canActivate: [DashboardGuard]
  },

  {
    path: 'panel',
    canActivate: [DashboardGuard],
    loadChildren: () =>
      import('./components/panel/panel.module').then((m) => m.PanelModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./components/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'legal',
    canActivate: [DashboardGuard],
    loadChildren: () =>
      import('./components/legal/legal.module').then((m) => m.LegalModule),
  },

  // { path: '**', component: NotfoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
  providers: [DashboardGuard],
})
export class AppRoutingModule { }
