<button *ngIf="!control.value" type="button" class="upload-btn w-100" tabindex="-1">
  <div class="upload-container px-5">
    <form tabindex="0" class="upload-card mb-2">
      <div class="fallback">
        <input name="file" type="file" #fileInput (change)="uploadFileEvt($event)" hidden id="fileInput" />
      </div>
      <div>
        <label for="fileInput">
          <svg xmlns="http://www.w3.org/2000/svg" height="5em" viewBox="0 0 640 512" class="mt-3">
            ! Font Awesome Free 6.4.2 by {{ '@fontawesome' }} - https://fontawesome.com
            License - https://fontawesome.com/license (Commercial License)
            Copyright 2023 Fonticons, Inc.
            <style>
              svg {
                fill: #002b5c;
              }
            </style>
            <path
              d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z" />
          </svg>
          <div class="m-3">
            <h5>Choose File</h5>
          </div>
        </label>
      </div>
    </form>
  </div>
</button>
<label *ngIf="control.value" type="button" class="btn btn-primary" for="fileInput1">
  chose insted image
</label>
<input name="file" type="file" #fileInput1 (change)="uploadFileEvt($event)" hidden id="fileInput1" />

<div class="card show-card" *ngIf="control.value">
  <img class="card-img-top" [src]="control.value" alt="Card image">
</div>
