import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { StoreChangesService } from 'projects/shared/services/store-changes.service';
import { IUser } from 'projects/shared/models/interfaces/user.model';
import { Subscription } from 'rxjs';
import { StaffLoginResponse } from 'projects/shared/models/interfaces/staff';
import { ProfileService } from '../../panel/setting/services/profile.service';
import { SideMenuService } from '../side-menu/services/side-menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user!: StaffLoginResponse;
  userAvatar!: string;
  subscription: Subscription = new Subscription();
  @Output() event: EventEmitter<boolean> = new EventEmitter();
  toggle = true;
  loader = false;
  darkMode = false;
  constructor(
    private storeChangesServ: StoreChangesService,
    private profileServ: ProfileService,
    private sideMenuService: SideMenuService

  ) { 

  }



  ngOnInit(): void {
    this.getUser();
    this.profileServ.userData.subscribe(
      res => {
        if (res?.avatar) this.userAvatar = res.avatar!
      }
    )

    this.profileServ.avatarLoader.subscribe(
      res => {
        this.loader = res;
      }
    )

    
    this.sideMenuService.darkMode.subscribe( 
      res => {
        this.darkMode = res;
      }
    )
    this.checkMode();
  }

  getUser() {
    this.subscription.add(
      this.storeChangesServ.getUserAdmin().subscribe({
        next: (response) => {
          this.user = response;
          this.profileServ.userData.next(response);
        },
      })
    );
  }

  triggerEvent(): void {
    this.toggle = !this.toggle;
    this.event.emit(this.toggle);
  }


  checkMode() {
    this.darkMode = localStorage.getItem('dark') == 'true' ? true : false;
  }

  @HostListener('window:storage', ['$event'])
  storageListener(event: StorageEvent) {
    console.log('event: ', event);
    let { key, oldValue } = event;
    if (key == 'dark') localStorage.setItem(key!, oldValue!);
  }
}
