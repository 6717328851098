<div class="container-fluid">
  <p-toast></p-toast>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-xxl-2">
      <div class="card mt-4 shadow d-orders">
        <div class="card-body">
          <h5 class="card-title">Orders.</h5>
          <p class="card-text"><i class="pi pi-shopping-cart"></i></p>
          <p class="card-text">{{ 10 }}</p>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-xxl-2">
      <div class="card mt-4 shadow d-users">
        <div class="card-body">
          <h5 class="card-title">Users</h5>
          <p class="card-text"><i class="pi pi-users"></i></p>
          <p class="card-text">{{ 100 }}</p>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-xxl-2">
      <div class="card mt-4 shadow d-products">
        <div class="card-body">
          <h5 class="card-title">Products</h5>
          <p class="card-text"><i class="pi pi-briefcase"></i></p>
          <p class="card-text">{{ 120 }}</p>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-xxl-2">
      <div class="card mt-4 shadow d-totalsales">
        <div class="card-body">
          <h5 class="card-title">Total Sales</h5>
          <p class="card-text"><i class="pi pi-dollar"></i></p>
          <p class="card-text">{{ 5000 }}</p>
        </div>
      </div>
    </div>
    <div routerLink="/dashboard/teamWork" class="col-12 col-sm-6 col-md-4 col-xl-3 col-xxl-2">
      <div class="card mt-4 shadow d-work-team">
        <div class="card-body">
          <h5 class="card-title">Team Work</h5>
          <p class="card-text"><i class="pi pi-users"></i></p>
          <p class="card-text">{{ 5 }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
