import { CheckoutRes, Orders, Invoice, InvoiceData, GetNote } from './../models/order';
import { Observable, catchError, tap } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Order, Count } from '../models/order';
import { environment } from '../environments/environment';
import { ICreateOrderNoteRequest } from '../models/interfaces/ICreateOrderNoteRequest';
import { IResponse } from '../models/interfaces/response.model';
import { IOrder } from '../models/interfaces/order.model';
import { noop } from 'rxjs';
import { SideMenuService } from './../../admin/src/app/components/layout/side-menu/services/side-menu.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private apiURL = environment.apiUrl;
  private url = `${this.apiURL}orders/`;

  constructor(
    private http: HttpClient,
    private sideMenuService: SideMenuService
  ) { 
    
  }

  getOrders(param: {} = {}): Observable<IResponse<Order>> {
    const params = new HttpParams({ fromObject: param });
    return this.http.get<IResponse<Order>>(`${this.url}admin?${params}`, {
      withCredentials: true,
    });
  }

  countOrders(queryParam = ''): Observable<Count[]> {
    return this.http.get<Count[]>(`${this.url}admin/count-orders/?email=${queryParam}`, {
      withCredentials: true,
    });
  }

  getCountOrders() {
    this.countOrders().pipe( tap(
      counts => {
        counts.map( ele => {
          if ( ele._id?.toLowerCase() == 'processing' ) {
            this.sideMenuService.orderCount_BS.next(ele.count!);
          }
        })
      }
    )).subscribe( noop, err => console.log(err) );
  }
  getOrder(Id: string): Observable<IOrder> {
    return this.http.get<IOrder>(`${this.url}admin/${Id}`, {
      withCredentials: true,
    });
  }

  updateOrder(order: Order): Observable<Order> {
    return this.http.patch<Order>(`${this.url}admin/${order._id}`, order, {
      withCredentials: true,
    });
  }

  deleteOrder(orderId: string): Observable<any> {
    return this.http.delete<any>(`${this.url}admin/${orderId}`, {
      withCredentials: true,
    });
  }

  createOrderNote(payload: ICreateOrderNoteRequest): Observable<Order> {
    return this.http.post<Order>(
      `${this.url}order-notes/${payload.id}`,
      { message: payload.message },
      { withCredentials: true }
    );
  }

  getInvoice(Id: string): Observable<Invoice> {
    return this.http.get<Invoice>(`${this.url}order-invoice/${Id}`, {
      withCredentials: true,
    });
  }

  getUserOrders(param: {} = {}): Observable<IResponse<Order>> {
    const params = new HttpParams({ fromObject: param });
    return this.http.get<IResponse<Order>>(`${this.url}user-orders?${params}`, {
      withCredentials: true
    });
  }

  getUserOrder(orderId: string): Observable<IOrder> {
    return this.http
      .get<IOrder>(`${this.url}user-orders/${orderId}`, {
        withCredentials: true,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getOrderNots(id:string):Observable<GetNote[]>{
    return this.http.get<GetNote[]>(`${this.url}order-notes/${id}`, {
      withCredentials: true,
    });
  }

  getUserInvoice(Id: string): Observable<Invoice> {
    return this.http.get<Invoice>(`${this.url}user-invoice/${Id}`, {
      withCredentials: true,
    });
  }
  
}
