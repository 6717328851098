<div>
  <div class="d-flex w-100">
    <div
      *ngIf="!showHeader"
      class="sidenav"
    >
    <app-side-menu></app-side-menu>
    </div>

    <div class="content" >
      <div class="d-flex flex-column align-items-start">
        <div *ngIf="!showHeader">
          <app-header (event)="toggleSidebareEvent($event)"></app-header>
        </div>
        <div class="w-100 p-0">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>

