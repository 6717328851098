import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as userActions from '../actions/user.action';
import { IUser } from '../models/interfaces/user.model';
export const usersFeatureKey = 'userModule';
export const adapter: EntityAdapter<IUser> = createEntityAdapter<IUser>({
  selectId: selectUserId,
});
export function selectUserId(x: IUser): string {
  return x.email as string;
}
export interface FeatureState extends EntityState<IUser> {
  getUsers: any;
  updatedUser: any;
  user: any;
  message: any;
  verfiyMessage: any;
  imageURL: any;
  token: any;
  updateSucceeded: boolean;
  twoFAVerified: any;
  twoFAActivated: any;
  changePassword: any;
  error: any;
  // note:any;
}

export interface AppState {
  userModule: FeatureState;
}
export const selectFeature = (state: AppState) => state.userModule;
export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();

export const selectFeatureUser = createSelector(
  selectFeature,
  (state: FeatureState) => state
);

export const selectUsers = createSelector(
  selectFeatureUser,
  (state: FeatureState) => state.getUsers
);
export const selectUser = createSelector(
  selectFeatureUser,
  (state: FeatureState) => state.user
);
export const selectSignIn = createSelector(
  selectFeatureUser,
  (state: FeatureState) => state.token
);
export const selectUpdatedUser = createSelector(
  selectFeatureUser,
  (state: FeatureState) => state.updatedUser
);
export const selectChangePassword = createSelector(
  selectFeatureUser,
  (state: FeatureState) => state.changePassword
);
export const selectChange = createSelector(
  selectFeatureUser,
  (state: FeatureState) => {
    state.message, state.updateSucceeded;
  }
);
export const select2FAImage = createSelector(
  selectFeatureUser,
  (state: FeatureState) => state.imageURL
);
export const select2FAVerified = createSelector(
  selectFeatureUser,
  (state: FeatureState) => state.twoFAVerified
);
export const select2FAActivated = createSelector(
  selectFeatureUser,
  (state: FeatureState) => state.twoFAActivated
);
export const selectVerfiy = createSelector(
  selectFeatureUser,
  (state: FeatureState) => state.verfiyMessage
);
export const selectLogout = createSelector(
  selectFeatureUser,
  (state: FeatureState) => state.updateSucceeded
);
export const selectAllUserEntities = createSelector(
  selectFeatureUser,
  selectAll
);

export const initialState: FeatureState = adapter.getInitialState({
  getUsers: {},
  updatedUser: null,
  user: null,
  changePassword: {},
  message: '',
  imageURL: {},
  token: '',
  verfiyMessage: {},
  updateSucceeded: false,
  twoFAActivated: {},
  twoFAVerified: {},
  error: {},
  // note:{}
});

const userReducer = createReducer(
  initialState,
  on(userActions.GET_ALL_USERS, (state) => ({ ...state })),
  on(userActions.GET_ALL_USERS_FAIL, (state) => ({ ...state })),
  on(userActions.GET_ALL_USERS_SUCCESS, (state, action) => {
    return { ...state, getUsers: action.payload };
  }),

  on(userActions.SIGNUP, (state) => ({ ...state, user: {} })),
  on(userActions.SIGNUP_FAIL, (state, action) => {
    return { ...state, user: action.payload };
  }),
  on(userActions.SIGNUP_SUCCESS, (state, action) => {
    return adapter.addOne(action.payload, { ...state, user: action.payload });
  }),

  on(userActions.SIGNIN, (state, action) => ({ ...state, token: '' })),
  on(userActions.SIGNIN_FAIL, (state, action) => {
    return { ...state, token: action.payload };
  }),
  on(userActions.SIGNIN_SUCCESS, (state, action) => {
    return { ...state, token: action.payload };
  }),

  on(userActions.FORGET_PASSWORD, (state, action) => ({
    ...state,
    verfiyMessage: {},
  })),
  on(userActions.FORGET_PASSWORD_FAIL, (state, action) => {
    return { ...state, verfiyMessage: action.payload };
  }),
  on(userActions.FORGET_PASSWORD_SUCCESS, (state, action) => {
    return { ...state, verfiyMessage: action.payload };
  }),

  on(userActions.RESET_PASSWORD, (state, action) => ({ ...state, user: {} })),
  on(userActions.RESET_PASSWORD_FAIL, (state) => ({ ...state })),
  on(userActions.RESET_PASSWORD_SUCCESS, (state, action) => {
    return { ...state, user: action.payload };
  }),

  on(userActions.CHANGE_PASSWORD, (state, action) => ({
    ...state,
    changePassword: {},
  })),
  on(userActions.CHANGE_PASSWORD_FAIL, (state, action) => {
    return { ...state, changePassword: action.payload };
  }),
  on(userActions.CHANGE_PASSWORD_SUCCESS, (state, action) => {
    return { ...state, changePassword: action.payload };
  }),

  on(userActions.VERIFY_EMAIL, (state) => ({ ...state })),
  on(userActions.VERIFY_EMAIL_FAIL, (state, action) => {
    return { ...state, verfiyMessage: action.payload };
  }),
  on(userActions.VERIFY_EMAIL_SUCCESS, (state, action) => {
    return { ...state, verfiyMessage: action.payload };
  }),

  on(userActions.VERIFY_ID, (state, action) => ({ ...state, user: {} })),
  on(userActions.VERIFY_ID_FAIL, (state, action) => ({ ...state, user: {} })),
  on(userActions.VERIFY_ID_SUCCESS, (state, action) => {
    return { ...state, user: action.payload };
  }),

  on(userActions.SET2FA, (state, action) => ({ ...state, imageURL: {} })),
  on(userActions.SET2FA_FAIL, (state, action) => ({
    ...state,
    imageURL: action.payload,
  })),
  on(userActions.SET2FA_SUCCESS, (state, action) => {
    return { ...state, imageURL: action.payload };
  }),

  on(userActions.VERIFY_2FA, (state, action) => ({
    ...state,
    twoFAVerified: {},
  })),
  on(userActions.VERIFY_2FA_FAIL, (state, action) => {
    return { ...state, twoFAVerified: action.payload };
  }),
  on(userActions.VERIFY_2FA_SUCCESS, (state, action) => {
    return { ...state, twoFAVerified: action.payload };
  }),

  on(userActions.ACTIVATE_2FA, (state, action) => ({
    ...state,
    twoFAActivated: {},
  })),
  on(userActions.ACTIVATE_2FA_FAIL, (state, action) => ({
    ...state,
    twoFAActivated: {},
  })),
  on(userActions.ACTIVATE_2FA_SUCCESS, (state, action) => {
    return { ...state, twoFAActivated: action.payload };
  }),

  on(userActions.UPDATE_USER, (state, action) => ({
    ...state,
    updateSucceeded: false,
  })),
  on(userActions.UPDATE_USER_FAIL, (state) => ({
    ...state,
    updateSucceeded: false,
  })),
  on(userActions.UPDATE_USER_SUCCESS, (state, action) => {
    return { ...state, updatedUser: action.payload, updateSucceeded: true };
  }),

  on(userActions.DELETE_USER, (state) => ({ ...state })),
  on(userActions.DELETE_USER_FAIL, (state) => ({ ...state })),
  on(userActions.DELETE_USER_SUCCESS, (state, action) => {
    return { ...state };
  }),

  on(userActions.LOGOUT, (state) => ({ ...state, user: {} })),
  on(userActions.LOGOUT_FAIL, (state) => ({ ...state })),
  on(userActions.LOGOUT_SUCCESS, (state, action) => {
    return { ...state, user: {} };
  }),

  on(userActions.RESEND_EMAIL, (state) => ({ ...state })),
  on(userActions.RESEND_EMAIL_FAIL, (state, action) => ({
    ...state,
    verfiyMessage: {},
  })),
  on(userActions.RESEND_EMAIL_SUCCESS, (state, action) => {
    return { ...state, verfiyMessage: action.payload };
  }),

  on(userActions.VALIDATE, (state) => ({ ...state })),
  on(userActions.VALIDATE_FAIL, (state, action) => ({
    ...state,
    user: action.payload,
  })),
  on(userActions.VALIDATE_SUCCESS, (state, action) => {
    return { ...state, user: action.payload };
  }),

  on(userActions.ADD_USER_NOTE, (state) => ({ ...state, user: {} })),
  on(userActions.ADD_USER_NOTE_FAIL, (state, action) => ({
    ...state,
    user: action.payload,
  })),
  on(userActions.ADD_USER_NOTE_SUCCESS, (state, action) => ({
    ...state,
    user: action.payload,
  }))
);
export function reducer(state: FeatureState, action: Action) {
  return userReducer(state, action);
}
