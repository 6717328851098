import { CommonModule, Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationStart, Router, RouterModule, ActivatedRoute } from '@angular/router';
import { UserStoreProvider } from 'projects/shared/providers/user.provider';
import { StoreChangesService } from 'projects/shared/services/store-changes.service';
import { ProfileService } from '../../panel/setting/services/profile.service';
import { StaffLoginResponse } from 'projects/shared/models/interfaces/staff';
import { SkeletonModule } from 'primeng/skeleton';
import { OrderService } from 'projects/shared/services/order.service';
import { WalletService } from 'projects/shared/services/wallet.service';
import { TicketSystemService } from 'projects/shared/services/ticket-system.service';
import { BehaviorSubject, noop, tap } from 'rxjs';
import { SideMenuService } from './services/side-menu.service';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [CommonModule, RouterModule, SkeletonModule],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss'
})
export class SideMenuComponent implements OnInit {
  mainSideItems!: ISideItems[];
  settingSideItems!: ISideItems[];
  user!: StaffLoginResponse;
  countTopups!: number;
  ordersCounts!: number;
  ticketsCounts!: number;
  darkMode = true;
  sideMenuInitialized = false;

  checkCounts = new BehaviorSubject<({ orders?: boolean, tickets?: boolean, topUp?: boolean })>({ orders: false, tickets: false, topUp: false })

  constructor(
    private userStore: UserStoreProvider,
    private router: Router,
    private storeChanges: StoreChangesService,
    private profileService: ProfileService,
    private orderServ: OrderService,
    private ticketSystemService: TicketSystemService,
    private walletServ: WalletService,
    private sideMenuService: SideMenuService,
    private storeChangesServ: StoreChangesService
  ) {
    this.initSidebarData();
    setTimeout(() => {
      this.watchRoute(location.pathname);
    }, 1000);
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Navigation started
        let locationPath = event.url;
        this.watchRoute(locationPath)
      }
    });
    this.getUserData();
    this.getCountTopups();
    this.getOrderCounts();
    this.getTicketsCounts();

    this.checkCounts.subscribe(res => {
      let { topUp, orders, tickets } = res;
      if (topUp && orders && tickets) {
        this.watchRoute(location.pathname);
        this.initSidebarData();
        this.sideMenuInitialized = true;
      }
    })
    this.checkMode();
  }

  ngOnInit(): void {
    this.orderServ.getCountOrders();
    this.walletServ.getCountTopUps();
    this.ticketSystemService.getCountByStatus();
  }


  initSidebarData() {
    this.mainSideItems = [
      {
        icon: 'pi pi-home',
        label: 'Dashboard',
        path: '/dashboard',
      },
      {
        icon: 'pi pi-sitemap',
        label: 'Categories',
        dropdown: true,
        expanded: false,
        subMenu: [
          {
            label: 'Main Categories',
            path: '/panel/categories/',
            route: { route: 'mainCategories' },
          },
          {
            label: 'Categories',
            path: '/panel/categories/',
            route: { route: 'categories' },
          },
          {
            label: 'Sub Categories',
            path: '/panel/categories/',
            route: { route: 'subCategory' },
          },
        ]
      },
      {
        icon: 'pi pi-users',
        label: 'Users',
        dropdown: true,
        expanded: false,
        subMenu: [
          {
            label: 'Users List',
            path: '/panel/users/usersList',
            class: 'usersList'
          },
          {
            label: 'Staff List',
            path: '/panel/users/staff',
            class: 'staff'
          },
          {
            label: 'Roles',
            path: '/panel/users/rolesUsers',
            class: 'rolesUsers',
          },
          {
            label: 'Ranks',
            path: '/panel/users/rankUsers',
            class: 'rankUsers'
          },
        ]
      },
      {
        icon: 'pi pi-list',
        label: 'Orders',
        dropdown: true,
        expanded: false,
        event: { have: this.countTopups || this.ordersCounts ? true : false },
        subMenu: [
          {
            label: 'Orders List',
            path: '/panel/orders/ordersList',
            class: 'ordersList',
            event: { value: this.ordersCounts }
          },
          {
            label: 'Top Up List',
            path: '/panel/orders/top-up',
            class: 'top-up',
            event: { value: this.countTopups }
          },
        ]
      },
      {
        icon: 'pi pi-wallet',
        label: 'Wallets',
        dropdown: true,
        expanded: false,
        subMenu: [
          {
            label: 'Wallets List',
            path: '/panel/wallets/wallets-list',
            class: 'wallets-list',
          },
          {
            label: 'Transaction Logs',
            // path: '/panel/wallets/transaction-logs',
            // class: 'transaction-logs'
          },
        ]
      },
      {
        icon: 'pi pi-ticket',
        label: 'Tickets',
        dropdown: true,
        expanded: false,
        event: { have: this.ticketsCounts ? true : false },
        subMenu: [
          {
            label: 'Ticket System',
            path: '/panel/tickets/ticket-list',
            class: 'ticket-list',
            event: { value: this.ticketsCounts }
          },
          {
            label: 'Contact Us',
            path: '/panel/contactUs/contacts-list',
            class: 'contacts-list'
          },
        ]
      },
      {
        icon: 'pi pi-th-large',
        label: 'Products',
        dropdown: true,
        expanded: false,
        subMenu: [
          {
            label: 'All Products',
            path: '/panel/products/product-list',
            class: 'product-list',
            class2: 'edit-product'
          },
          {
            label: 'New Product',
            path: '/panel/products/new-product/form',
            class: 'new-product'
          },
          {
            label: 'Flash Sale',
            path: '/panel/products/flash-sale',
            class: 'flash-sale'
          },

        ]
      },
      {
        icon: 'pi pi-bell',
        label: 'Notification',
        path: '/panel/notifications',
      },
      {
        icon: 'pi pi-money-bill',
        label: 'Payments',
        path: 'panel/payments',
      },
      {
        icon: 'pi pi-flag',
        label: 'Reports',
        path: '/panel/reports',
      },
      {
        icon: 'fa fa-headset',
        label: 'Suppliers',
        path: '/panel/suppliers'
      },
      {
        icon: 'pi pi-sliders-h',
        label: 'Sliders',
        path: '/panel/sliders-center',
      },
      {
        icon: 'fa-solid fa-scale-balanced',
        label: 'Legal',
        dropdown: true,
        expanded: false,
        subMenu: [
          {
            label: 'F A Q',
            path: '/legal/FAQ',
            class: 'FAQ'
          },
          {
            label: 'Blogs',
            path: '/legal/blogs',
            class: 'blogs'
          },
          {
            label: 'Privacy Policy',
            path: '/legal/privacy-policy',
            class: 'privacy-policy'
          },
        ]
      },
      {
        icon: 'pi pi-dollar',
        label: 'Licenses',
        dropdown: true,
        expanded: false,
        subMenu: [
          {
            label: 'Licenses List',
            path: '/panel/licenses/licensesList',
            class: 'licensesList'
          },
          {
            label: 'Licenses Manager',
            path: '/panel/licenses/manager',
            class: 'manager'
          }
        ]
      },
    ];

    this.settingSideItems = [
      {
        icon: 'pi pi-cog',
        label: 'Setting',
        dropdown: true,
        expanded: false,
        subMenu: [
          {
            label: 'Profile',
            path: '/panel/setting/profile',
            class: 'profile'
          },
          {
            label: 'Maintenance',
            path: '/panel/mintanance',
            class: 'mintanance'
          },
        ]
      },
      {
        icon: 'pi pi-sign-out',
        label: 'Logout Account',
        logout: () => this.logout()
      },

    ]
  }

  onDropdownClick(event: MouseEvent) {
    let t: any = event.target;
    let items: any = t.closest('.items');
    let item: any = t.closest('.item');
    let subMenuItems = t.closest('.item')?.querySelector('.sub-menu')?.querySelectorAll('.sub-menu-item');
    let y = item?.offsetTop - items?.offsetTop;
    let scrollValue = y += (subMenuItems?.length * 70);
    item?.scrollTo({ behavior: 'smooth', top: scrollValue });
  }

  getCountTopups() {
    this.sideMenuService.topUpsCount_BS.pipe(tap(
      res => {
        this.countTopups = res;
        if (!this.sideMenuInitialized) {
          let currentValue = this.checkCounts.value;
          currentValue.topUp = true;
          this.checkCounts.next(currentValue);
        } else {
          this.initSidebarData();
          this.watchRoute(location.pathname);
        }
        this.storeChangesServ.setCountTopups(this.countTopups);
      }
    )).subscribe(noop, (err => console.log(err)))


  }

  getOrderCounts() {
    this.sideMenuService.orderCount_BS.pipe(tap(
      res => {
        this.ordersCounts = res;
        if (!this.sideMenuInitialized) {
          let currentValue = this.checkCounts.value;
          currentValue.orders = true;
          this.checkCounts.next(currentValue);
        } else {
          this.initSidebarData();
          this.watchRoute(location.pathname);
        }

        this.storeChangesServ.setCountOrders(this.ordersCounts)
      }
    )).subscribe(noop, err => console.log(err))
  }

  getTicketsCounts() {
    this.sideMenuService.ticketCount_BS.pipe(tap(
      res => {
        this.ticketsCounts = res;
        if (!this.sideMenuInitialized) {
          let currentValue = this.checkCounts.value;
          currentValue.tickets = true;
          this.checkCounts.next(currentValue);
        } else {
          this.initSidebarData();
          this.watchRoute(location.pathname);
        }
      }
    )).subscribe(noop, err => console.log(err))
  }

  collapseSiblings(index: number) {
    this.settingSideItems.map((ele, i) => {
      if (index != i) ele.expanded = false
    });
    this.mainSideItems.map((ele, i) => {
      if (index != i) ele.expanded = false
    })
  }


  watchRoute(locationPath: string) {
    // ! ====   For Categories  =======
    let route = locationPath.split('/').slice(3, 8).toString();
    this.mainSideItems.map(ele => {
      if (ele.label.toLowerCase() == 'categories') {
        if (locationPath?.includes(ele.label.toLowerCase())) {
          ele.expanded = true;
          ele.active = true;
        } else {
          ele.expanded = false;
          ele.active = false;
        }
        ele.subMenu?.map(subEle => {
          if (route.includes(subEle.route?.route!) || locationPath.includes(`route=${subEle.route?.route}`)) subEle.active = true;
          else subEle.active = false;
        })
      }
      // ! ================================================================
      // ! for others;
      if (ele.label.toLowerCase() != 'categories') {
        if (locationPath.includes(ele.label.toLowerCase())) {
          ele.expanded = true;
          ele.active = true;
        } else {
          ele.expanded = false;
          ele.active = false;
        }
        ele.subMenu?.map(subEle => {
          if (locationPath.includes(subEle.class!) || locationPath.includes(subEle.class2!)) {
            subEle.active = true;
          } else {
            subEle.active = false;
          }

        })
        if (ele.label.toLowerCase() == 'tickets') {
          if (locationPath.includes('contactUs')) {
            ele.active = true;
            ele.expanded = true;
          }
        }
      }
    });
    // ! =========================================================================



    this.settingSideItems.map(ele => {
      if (locationPath.includes(ele.label.toLowerCase())) {
        ele.expanded = true;
        ele.active = true;
      } else {
        ele.expanded = false;
        ele.active = false;
      }
      ele.subMenu?.map(subEle => {
        if (locationPath.includes(subEle.class!)) {
          subEle.active = true;
        } else {
          subEle.active = false;
        }
      })
      if (ele.label.toLowerCase() == 'setting') {

        if (locationPath.includes('mintanance')) {
          ele.expanded = true;
          ele.active = true;
        }

      }
    })

  }

  logout() {
    this.userStore.logout$().subscribe((res) => {
      localStorage.removeItem('userRole');
      localStorage.removeItem('isLoggedIn');
      this.router.navigateByUrl('/auth/login');
      this.storeChanges.setUser({});
    });
  }

  getUserData() {
    this.profileService.userData.subscribe(
      res => {
        this.user = res;
      }
    )
  }

  checkMode() {
    this.darkMode = localStorage.getItem('dark') == 'true' ? true : false;
  }

  @HostListener('window:storage', ['$event'])
  storageListener(event: StorageEvent) {
    let { key, oldValue } = event;
    if (key == 'dark') localStorage.setItem(key!, oldValue!)
  }

  setKeyForMode() {
    this.sideMenuService.darkMode.next(this.darkMode ? true : false);
    localStorage.setItem('dark', this.darkMode ? 'true' : 'false');
  }

}


interface ISideItems {
  icon?: string;
  label: string;
  route?: { route: string };
  path?: string;
  class?: string;
  class2?: string;
  dropdown?: boolean;
  expanded?: boolean;
  subMenu?: ISideItems[];
  logout?: any;
  active?: boolean;
  event?: { have?: boolean, value?: number }
}