import { Injectable } from '@angular/core';
import { Count } from 'projects/shared/models/order';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {
  darkMode = new BehaviorSubject<boolean>(false);

  orderCount_BS = new BehaviorSubject<number>(0);
  ticketCount_BS = new BehaviorSubject<number>(0);
  topUpsCount_BS = new BehaviorSubject<number>(0);
}
